import grey from "@mui/material/colors/grey";

const getDesignTokens = (mode = "light") => ({
  typography: {
    allVariants: {
      fontFamily: "Proxima Nova,Helvetica,Arial,sans-serif Nova",
    },
  },
  palette: {
    mode,
    ...(mode === "light"
      ? {
          primary: {
            main: "#A8B136",
            background: "#202020",
            default: "#A8B136",
            message: "#202020",
            white: "#fff",
            blackX: "#fff",
            hover: "#857FB3",
            chatBack: `#FCFCFC`,
            contrastText: "orange",
          },
          secondary: {
            main: "#4849A1",
            background: "#008777",
            default: "#4849A1",
          },
          divider: "#000000",
          text: {
            primary: "#000000",
            secondary: "#757575",
            border: "#F5F5F5",
            mainGrey: "#FCFCFC",
          },
          button: {
            main: "#C53232",
          },
          background: {
            default: "#fff",
            paper: "#fff",
            main: "#fff",
            message: "#4849A1",
            postFoot: "#F2F3F9",
            grey: "#FCFCFC",
            secondary: "#FCFCFC",
          },
          main_baack: "#fff",
          appBar: {
            background: "#fff",
          },
          buttonWhite: `#fff`,
        }
      : {
          primary: {
            main: "#4849A1",
            background: "#202225",
            default: "#fff",
            message: "#202225",
            white: "#fff",
            blackX: "#0000",
            hover: "#857FB3",
          },
          secondary: {
            main: "#f85a5b",
            background: "#202225",
            default: "#fff",
          },
          divider: "#000000",
          background: {
            default: "#121212",
            paper: "#202225",
            message: "#202225",
            postFoot: "#121212",
            grey: "#202225",
          },
          text: {
            primary: "#fff",
            secondary: grey[500],
            border: "#202225",
            mainGrey: "#00000",
          },
          main_baack: "#000000",
        }),
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          height: 0,
        },
      },
    },
    MuiTextareaAutosize: {
      styleOverrides: { root: { background: "#202225" } },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: "Proxima Nova,Helvetica,Arial,sans-serif",
          ...(mode === "light"
            ? {
                scrollbarColor: "#6b6b6b #202225",
                "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                  backgroundColor: "#fff",
                  width: 8,
                },
                "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                  borderRadius: 8,
                  backgroundColor: "#4849A1",
                  minHeight: 24,
                  border: "3px solid #4849A1",
                },
                "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                  {
                    backgroundColor: "#4849A1",
                  },
                "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                  {
                    backgroundColor: "#4849A1",
                  },
                "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                  {
                    backgroundColor: "#4849A1",
                  },
                "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                  backgroundColor: "transparent",
                },
              }
            : {
                scrollbarColor: "#6b6b6b #202225",
                "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                  backgroundColor: "#202225",
                  width: 10,
                },
                "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                  borderRadius: 8,
                  backgroundColor: "#6b6b6b",
                  minHeight: 24,
                  border: "3px solid #202225",
                },
                "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                  {
                    backgroundColor: "#959595",
                  },
                "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                  {
                    backgroundColor: "#959595",
                  },
                "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                  {
                    backgroundColor: "#959595",
                  },
                "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                  backgroundColor: "#202225",
                },
              }),
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid",
          borderColor: mode === "light" ? grey[200] : grey[800],
          fontFamily: "Proxima Nova,Helvetica,Arial,sans-serif",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid",
          borderColor: mode === "light" ? grey[200] : grey[800],
          fontFamily: "Proxima Nova,Helvetica,Arial,sans-serif",
        },
      },
    },
  },
});

export default getDesignTokens;
